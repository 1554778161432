// 👇 Third Party Components { Start
import { jsPDF } from "jspdf"
import { utils, writeFile } from 'xlsx'
import html2canvas from 'html2canvas'
import jwt_decode from 'jwt-decode'
// ☝ Third Party Components End }
import { DefaultRoute, HRDashboardRoute, AdminDashboardRoute, AnyDashboardRoute, LimitedDashboardRoute } from '../router/routes' // 👈️ DefaultRoute Imports
import CopyToClipboard from 'react-copy-to-clipboard'
import { Copy } from 'react-feather'
import toast from 'react-hot-toast'
import moment from "moment/moment"
import { TaskTimer } from 'tasktimer'
import Avatar from '@components/avatar'
import { urlAzureBlobServices, urlAuthService } from '@src/endpoints'
import defaultAvatar from '@src/assets/images/portrait/small/avatar.png'
import { CheckContainerNumberPerIsoSD } from "./ContainerNumberUtils"
import axios from 'axios'
import ExcelJS from 'exceljs'

const weightWithCommas = x => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const weightFormat = num => { return `${weightWithCommas(num)}` }

const imageExists = (image_url) => {
  const http = new XMLHttpRequest()
  http.open('HEAD', image_url, false)
  http.send()
  return http.status !== 404
}

// 👇 Renders Client Columns
export const renderUserAvatar = avatar => {
  const image_url = `${urlAzureBlobServices}/${avatar}`
  if (avatar?.length && avatar?.includes('.') && imageExists(image_url)) {
    return <Avatar className='me-1' img={image_url} width='32' height='32' />
  } else {
    return (
      <Avatar className='me-1' img={defaultAvatar} width='32' height='32' />
    )
  }
}
// phone number validation
export const validatePhoneNumber = (value) => {
  if (!value) {
    return 'Phone number is required'
  }

  // Remove non-numeric characters
  const numericValue = value.replace(/\D/g, '')

  // Check if the numeric value has exactly 10 digits
  return numericValue.length === 10 ? true : 'Please enter a 10-Digit valid phone number'
}

// 👇 Renders Client Columns
export const renderVesselCountry = flag => {
  const image_url = `https://www.worldatlas.com/r/w425/img/flag/${flag?.toLowerCase()}-flag.jpg`
  if (flag?.length && image_url?.includes('.')) {
    return <Avatar className='me-1' img={image_url} width='32' height='32' />
  } else {
    return (
      <Avatar className='me-1' img={defaultAvatar} width='32' height='32' />
      //<Avatar className='me-1' img={image_url} width='32' height='32' />
    )
  }
}

export const isObjEmpty = obj => Object.keys(obj).length === 0 // 👈️ Checks if an object is empty (returns boolean)
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num) // 👈️ Returns K format from a number
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '') // 👈️ Converts HTML to string

String.prototype.minsToHHMMSS = function () {
  const mins_num = parseFloat(this, 10) // don't forget the second param
  let hours = Math.floor(mins_num / 60)
  let minutes = Math.floor((mins_num - (((hours * 3600)) / 60)))
  let seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60))

  // Appends 0 when unit is less than 10
  if (hours < 10) { hours = `0${hours}` }
  if (minutes < 10) { minutes = `0${minutes}` }
  if (seconds < 10) { seconds = `0${seconds}` }
  return `${hours}:${minutes}:${seconds}`
}

/*
//Function to convert minuted to HH:MM:SS format
export const minsToHHMMSS = (mins) => {
  const hours = Math.floor(mins / 60)
  const minutes = Math.floor(mins % 60)
  const seconds = 0
  return `${hours}:${minutes}:${seconds}`
}

*/

String.prototype.minsTohrm = function () {
  const mins_num = parseFloat(this, 10) // don't forget the second param
  const hours = Math.floor(mins_num / 60)
  const minutes = Math.floor((mins_num - (((hours * 3600)) / 60)))
  return hours === 0 ? `${minutes} min` : `${hours} hrs ${minutes} min`
}

String.prototype.minsTohr = function () {
  const mins_num = parseFloat(this, 10) // don't forget the second param
  let hours = Math.floor(mins_num / 60)
  let minutes = Math.floor((mins_num - (((hours * 3600)) / 60)))
  if (hours < 10) { hours = `0${hours}` }
  if (minutes < 10) { minutes = `0${minutes}` }
  return hours === 0 ? `0.${minutes}` : `${hours}.${minutes}`
}

const isToday = date => { // 👈️ Checks if the passed date is today
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

// const twoDigitFormat = data => { // 👈️ Convert date/month single digit to two digit
//   return `0${data}`.slice(-2)
// }

export const twoDigitFormat = data => { // 👈️ Convert date/month single digit to two digit
  return `0${data}`.slice(-2)
}

export const getDateOnly = (date, type) => { // 👈️ Convert actual date format
  if (date !== undefined && date !== null) {
    const dateOnly = date.split(type)[0]
    return dateOnly
  } else {
    return null
  }
}

export const getTimeOnly = (date, type) => { // 👈️ Convert actual Time format
  if (date !== undefined && date !== null) {
    let timeOnly = date.split(type)[1]
    timeOnly = timeOnly.includes('.') ? timeOnly.split('.')[0] : timeOnly
    return timeOnly
  } else {
    return null
  }
}

export const getNoOfMinutes = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const minutes = Math.floor(((endDate - startDate) / (1000 * 60)))
  return minutes
}

export const getNoOfHours = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const hours = ((endDate - startDate) / (1000 * 60 * 60)).toFixed(2)
  return hours
}

export const getHHMMSS = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const minutes = ((endDate - startDate) / (1000 * 60))
  return minutes.toString().minsToHHMMSS()
}

export const getHrForChart = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const minutes = ((endDate - startDate) / (1000 * 60))
  let chartPercent = minutes.toString().minsTohr() * 12.05 // 12.05 is used for adjusting 08hr 30m to 100% in chart
  chartPercent = chartPercent.toString().includes('.') ? chartPercent.toString().split('.')[0] : chartPercent
  return chartPercent > 0 ? chartPercent : 0
}

export const getHMSAddMin = (start, end, addMin) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  addMin = (addMin === undefined || null) ? 0 : addMin
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const minutes = ((endDate - startDate) / (1000 * 60)) + addMin
  return minutes.toString().minsToHHMMSS()
}

export const getSeconds = (start, end) => { // 👈️ Get seconds
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const seconds = ((endDate - startDate) / (1000))
  return seconds
}

export const getHrFromMin = (minutes) => { // 👈️ Convert actual hours
  if (minutes === null) return '00:00:00'
  return minutes.toString().minsToHHMMSS()
}

export const getHrmFromMin = (minutes) => { // 👈️ Convert actual hours
  if (minutes === null) return '0 min'
  return minutes.toString().minsTohrm()
}

export const getConvertedHours = (hoursMins) => { // 👈️ Convert actual hours
  if (hoursMins === null) return '00:00:00'
  const hours = hoursMins.split(':')[0]
  const mins = hoursMins.split(':')[1]
  const convertedHours = ((60 * hours) + (mins * 1)) / (60)
  return convertedHours.toFixed(2)
}

export const treatAsUTC = (date) => {
  const result = new Date(date)
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
  return result
}

export const noOfDays = (start, end) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  const milliseconds = 60 * 60 * 1000
  let day = Math.ceil((treatAsUTC(end) - treatAsUTC(start)) / millisecondsPerDay)
  const hours = Math.ceil((treatAsUTC(end) - treatAsUTC(start)) / milliseconds)
  day = day > 1 ? `${day} Days` : hours > 7 ? '1 Day' : `${hours} Hours`
  return day
}

export const getBusinessDays = (startDate, endDate) => {
  const weekends = new Set([0, 6]) // Sunday (0) and Saturday (6) are considered weekends
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  let businessDays = 0

  const currentDate = new Date(startDate)
  const endDateTime = new Date(endDate)

  while (currentDate <= endDateTime) {
    if (!weekends.has(currentDate.getDay())) {
      businessDays++
    }

    currentDate.setTime(currentDate.getTime() + millisecondsPerDay)
  }

  return businessDays
}

export const countWorkingDays = (startDate, endDate) => {
  const businessDays = getBusinessDays(startDate, endDate)
  const workingDays = businessDays > 1 ? `${businessDays} Days` : '1 Day'

  return workingDays
}

// ** Renders WorkDone Columns
export const displayHoursWorked = (start) => {
  return getHHMMSS(start, new Date().toLocaleString('en-US', { hour12: true }))
}

export const getHRM = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const minutes = ((endDate - startDate) / (1000 * 60))
  return minutes.toString().minsTohrm()
}

export const getBurnOut = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const diff = endDate - startDate
  const burnOut = (diff / (1000 * 60 * 60))
  return Math.floor(burnOut * 10)
}

export const getBurnOutColor = (start, end) => { // 👈️ Convert actual hours
  if (start === null || end === null) return null
  const startDate = moment(start).valueOf()
  const endDate = moment(end).valueOf()
  const burnOut = ((endDate - startDate) / (100 * 60 * 60)).toFixed(2)
  if (burnOut <= 25) {
    return 'progress-bar-info'
  } else if (burnOut <= 50) {
    return 'progress-bar-purple'
  } else if (burnOut <= 75) {
    return 'progress-bar-warning'
  } else if (burnOut <= 90) {
    return 'progress-bar-success'
  } else if (burnOut > 90) {
    return 'progress-bar-primary'
  }
}

export const getBurnOutColorByHour = (hours) => { // 👈️ Convert actual hours
  if (hours === null) return null
  if (hours <= 25.0) {
    return 'progress-bar-info'
  } else if (hours <= 50.0) {
    return 'progress-bar-purple'
  } else if (hours <= 75.0) {
    return 'progress-bar-warning'
  } else if (hours <= 90.0) {
    return 'progress-bar-success'
  } else if (hours > 90.0) {
    return 'progress-bar-primary'
  }
}


export const getBurnOutColorByContribution = (contribution) => { // 👈️ Convert actual hours
  if (contribution === null) return null
  if (contribution <= 5000) {
    return 'progress-bar-info'
  } else if (contribution <= 6000) {
    return 'progress-bar-purple'
  } else if (contribution <= 7000) {
    return 'progress-bar-warning'
  } else if (contribution <= 8000) {
    return 'progress-bar-success'
  } else if (contribution > 9000) {
    return 'progress-bar-primary'
  }
}

// export const getDateOnly = (date) => { // 👈️ Convert UTC Date to Local Date format
//   if (date !== undefined && date !== null) {
//     const offset = new Date().getTimezoneOffset()
//     const utcDateTime = new Date(date)
//     utcDateTime.setHours(utcDateTime.getHours() - (offset / 60))
//     const pstDateTime = new Date(utcDateTime)

//     const displayDate = `${pstDateTime.getFullYear()}-${twoDigitFormat(pstDateTime.getMonth() + 1)}-${twoDigitFormat(pstDateTime.getDate())}`
//     return displayDate
//   } else {
//     return null
//   }
// }

// export const getDateTime = (date) => {
//   if (date !== undefined && date !== null) {
//     const offset = new Date().getTimezoneOffset()
//     const utcDateTime = new Date(date)
//     utcDateTime.setHours(utcDateTime.getHours() - (offset / 60))
//     const pstDateTime = new Date(utcDateTime)
//     let formattedTime = pstDateTime.toString().split(' ')[4]
//     const hour = parseInt(formattedTime.split(':')[0])
//     if (hour === 0) {
//       formattedTime = `12:${formattedTime.split(':')[1]} AM`
//     } else if (hour >= 12) {
//       formattedTime = `${hour - 12}:${formattedTime.split(':')[1]} PM`
//     } else {
//       formattedTime = `${hour}:${formattedTime.split(':')[1]} AM`
//     }
//     const displayDateTime = `${pstDateTime.getFullYear()}-${twoDigitFormat(pstDateTime.getMonth() + 1)}-${twoDigitFormat(pstDateTime.getDate())} ${formattedTime}`
//     return displayDateTime
//   } else {
//     return null
//   }
// }
// function to convert date to ISO format
export const toISOString = (date) => {
  if (date instanceof Date) {
    return date.toISOString()
  } else if (typeof date === 'string') {
    return new Date(date).toISOString()
  }
  return null
}
const twooDigitFormat = (num) => num.toString().padStart(2, '0')

export const getDateTime = (date) => {
  if (date !== undefined && date !== null) {
    // Parse the UTC date
    const utcDate = new Date(date)
    
    // Convert to local date
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }))
    
    // Get local date components
    const year = localDate.getFullYear()
    const month = twooDigitFormat(localDate.getMonth() + 1)
    const day = twooDigitFormat(localDate.getDate())
    const hours = localDate.getHours()
    const minutes = twooDigitFormat(localDate.getMinutes())
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = twooDigitFormat(hours % 12 || 12)
    
    // Construct the display date-time string
    const displayDateTime = `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`
    
    return displayDateTime
  } else {
    return null
  }
}
export const getLocalDateTime = (date) => { // 👈️ Convert UTC date to Local DateTime format
  if (date !== undefined && date !== null) {
    const offset = new Date().getTimezoneOffset()
    const utcDateTime = new Date(date)
    utcDateTime.setHours(utcDateTime.getHours() - (offset / 60))
    const pstDateTime = new Date(utcDateTime)
    let formattedTime = pstDateTime.toString().split(' ')[4]
    const hour = parseInt(formattedTime.split(':')[0])
    if (hour === 0) {
      formattedTime = `12:${formattedTime.split(':')[1]} AM`
    } else if (hour >= 12) {
      formattedTime = `${hour - 12}:${formattedTime.split(':')[1]} PM`
    } else {
      formattedTime = `${hour}:${formattedTime.split(':')[1]} AM`
    }
    const displayDateTime = `${pstDateTime.getFullYear()}-${twoDigitFormat(pstDateTime.getMonth() + 1)}-${twoDigitFormat(pstDateTime.getDate())}T${formattedTime}`
    return displayDateTime
  } else {
    return null
  }
}

export const getLocalDateOnly = (date, type) => { // 👈️ Convert actual date format
  if (date !== undefined && date !== null) {
    const localDate = getLocalDateTime(date)
    const dateOnly = localDate.split(type)[0]
    return dateOnly
  } else {
    return null
  }
}

export const getLocalTimeOnly = (date, type) => { // 👈️ Convert actual Time format
  if (date !== undefined && date !== null) {
    const localDate = getLocalDateTime(date)
    const timeOnly = localDate.split(type)[1]
    return timeOnly
  } else {
    return null
  }
}

export const getShortFormatDate = date => { // 👈️ Convert to short date format (YYYY-MM-DD)
  const result = `${date?.getFullYear()}-${twoDigitFormat(date?.getMonth() + 1)}-${twoDigitFormat(date?.getDate())}`
  return result
}

export const getFileNameFromDate = () => { // 👈️ Convert to short date format (YYYY-MM-DD)
  const date = new Date()
  const result = `${date?.getFullYear()}_${date?.getMonth() + 1}_${date?.getDate()}_${date?.getHours()}${date?.getMinutes()}${date.getSeconds()}`
  return result
}

// Timer with 1000ms (1 second) base interval resolution.
const timer = new TaskTimer(1000)
let timer_id = 1000
// interval can be updated anytime by setting the `timer.interval` property.
export const addTimer = (_id, start, minutes) => {
  timer_id = timer_id + 1
  if (start !== null) {
    timer.add([
      {
        id: timer_id,       // unique ID of the task
        tickInterval: 1,    // run every 5 ticks (5 x interval = 5000 ms)
        totalRuns: 0,      // run 10 times only. (set to 0 for unlimited times)
        callback() {
          // code to be executed on each run
          //console.log(`${task.id    flag = flag.split('    flag = flag.split(' ').pop() true })))
          document.getElementById(_id).innerHTML = getHMSAddMin(start, new Date().toLocaleString('en-US', { hour12: true }), minutes)
        }
      }
    ])
    // Start the timer
    timer.start()
  }
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatTime = (value, formatting = { hour: 'numeric', minute: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => { // 👈️ Returns short month of passed date
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }
  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  } return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const createPDF = (data, fileName, width, height) => { // 👈️ Create PDF file from JSON data
  const jsPdf = new jsPDF('landscape', "px", [width, height])
  const headers = Object.keys(data[0])
  jsPdf.table(1, 1, data, headers, { autoSize: true })
  jsPdf.save(fileName)
}

const convertArrayOfObjectsToCSV = (data) => { // 👈️ Converts table to CSV
  let result
  const columnDelimiter = ','
  const lineDelimiter = '\n'
  const keys = Object.keys(data[0])
  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter
  data.forEach(item => {
    let ctr = 0
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter
      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })
  return result
}

export const createCSV = (data, fileName) => { // 👈️ Create CSV file from JSON data
  const link = document.createElement('a')
  let csv = convertArrayOfObjectsToCSV(data)
  if (csv === null) return
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csvcharset=utf-8,${csv}`
  }
  link.setAttribute('href', encodeURI(csv))
  link.setAttribute('download', fileName)
  link.click()
}

export const createExcel = (data, fileName, isAOA) => { // 👈️ Create Excel file from JSON data
  const ws = isAOA ? utils.aoa_to_sheet(data) : utils.json_to_sheet(data)
  const wb = utils.book_new()
  const wsName = fileName.includes('_') ? fileName.split('_')[0] : fileName // 👈️ Split file name into readable format
  utils.book_append_sheet(wb, ws, wsName)
  writeFile(wb, fileName)// 👈️ generate XLSX file and send to client
}

export const convertJsonToAoa = (headers, data) => {
  const headersData = headers.map((d) => d.label)
  const rows = data.map((d) => headers.map((h) => d[h.key] || ''))
  return [headersData, ...rows]
}

export const JSONParse = (data) => { // 👈️ Convert to string and Parse to JSON data
  const stringData = JSON.parse(JSON.stringify(data, (key, value) => (typeof (value) === 'number' ? `${value}` : value)))
  return JSON.parse(JSON.stringify(stringData, (key, value) => (value === null ? 'N/A' : value)))
}

export const countryFlag = (img) => {  // 👈️ Get Country Flag
  if ((img !== null && img !== undefined && img !== 'N/A')) {
    let flag = (img.includes('(') ? img.split('(')[1] : img)
    flag = flag.split(' ').pop()
    const imgPath = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${flag.toLowerCase().substring(0, 2)}.svg`
    return imgPath
  }
  return ''
}

export const getLabel = (arr, value) => {  // 👈️ Get Label from DD List
  if (Array.isArray(arr) && value !== null && value !== undefined) {
    return arr[arr.findIndex(obj => obj.value.toString() === value.toString())]?.label
  } else {
    return 'N/A'
  }
}

export const getLabelText = (arr, label, title) => { // 👈️ Get Label from DD List passing Title
  if (Array.isArray(arr) && label !== null && label !== undefined && label !== '') {
    return arr[arr.findIndex(obj => obj.label.toString() === label.toString())]?.label
  } else {
    return title
  }
}

export const getFFstatus = (status) => { // 👈️ Get FF Status
  if (status === 'Rejected' || status === 'Completed') {
    return true
  }
  return false
}

export const getInboundRequestStatus = (status) => { // 👈️ Get Inbound Request Status
  if (status !== "CREATED") {
    return true
  }
  return false
}

export const html2PdfDoc = (id, fileName) => { // 👈️ Convert HTML to PDF
  const htmlId = document.getElementById(id)
  const doc = new jsPDF()
  doc.html(htmlId, {
    callback(doc) {
      // Save the PDF
      doc.save(fileName)
    },
    x: 15,
    y: 15,
    width: 180, //target width in the PDF document
    windowWidth: htmlId.offsetWidth //window width in CSS pixels
  })
}

export const html2PdfReport = (id, fileName, width, height) => { // 👈️ Convert HTML to PDF
  //html2PdfDoc(id, fileName)
  if (height < width) {
    height = 900
  }
  const htmlId = document.getElementById(id)
  for (let i = 0; i < 2; i++) {
    html2canvas(htmlId).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "pt", [width, height], true)
      pdf.addImage(imgData, "PNG", 60, 25)
      if (i === 1) {
        pdf.save(fileName)
      }
    })
  }
}

export const html2Pdf = (id, fileName, size) => { // 👈️ Convert HTML to PDF
  const htmlId = document.getElementById(id)
  html2canvas(htmlId).then((canvas) => {
    const imgData = canvas.toDataURL("image/png")
    const pdf = new jsPDF("p", "pt", size)
    pdf.addImage(imgData, "JPEG", 60, 25)
    pdf.save(fileName)
  })
}

export const randomKeyGen = (min) => { // 👈️ Random Key For HTML elements
  const random = Math.floor(Math.random() * (100000000000000 - min + 1)) + min
  return random
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const CopyText = ({ text }) => {
  const onCopy = () => {
    toast.success('Copied To Clipboard !')
  }
  return (
    <CopyToClipboard onCopy={onCopy} text={text}>
      <Copy size={14} style={{ cursor: 'pointer', color: 'GrayText' }} />
    </CopyToClipboard>
  )
}

export const isValidToken = async () => {
  const response = await axios.get(`${urlAuthService}/IsValidToken`)
  if (!response.data.success) {
    window.location = "/login"
  }
  return response
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => { // 👈️ Check User Logged In
  const token = localStorage.getItem('accessToken')
  const decodedToken = jwt_decode(token)
  const currentDate = new Date()
  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    window.location = "/login"
  } else {
    isValidToken()
    //console.log("Valid token")
    //console.log("decodedToken:", decodedToken)
    //console.log("roles:", decodedToken.role)
    return true
  }
}

//get user role from token
export const getUserRole = () => { // 👈️ Get User Role
  const token = localStorage.getItem('accessToken')
  const decodedToken = jwt_decode(token)
  return decodedToken.role
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData')) // 👈️ getUserData from Local Storage

export const getUriParams = params => { // 👈️ Arrange URL Parameters
  const uri = `page=${params.page}&perPage=${params.perPage}&status=${params.status}&q=${params.q}&sort=${params.sort}&sortColumn=${params.sortColumn}`
  return uri
}

export const getUserUriParams = params => { // 👈️ Arrange User URL Parameters
  const confirmed = `${(params.confirmed === null || params.confirmed === undefined) ? '' : '&confirmed=true'}`
  const role = (params.role === '' || params.role === null || params.role === undefined) ? '' : `&role=${params.role}`
  const department = (params.department === '' || params.department === null || params.department === undefined) ? '' : `&department=${params.department}`
  const q = (params.q === '' || params.q === null || params.q === undefined) ? '' : `&q=${params.q}`
  const status = (params.status === '' || params.status === null || params.status === undefined) ? '' : `&status=${params.status}`
  const uri = `page=${params.page}&perPage=${params.perPage}${status}${q}&sort=${params.sort}&sortColumn=${params.sortColumn}${department}${role}${confirmed}`
  return uri
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => { // 👈️ Get Home Routes
  const loggedInUser = getUserRole()
  if (loggedInUser?.indexOf('masteradmin') !== -1) return AdminDashboardRoute
  if (loggedInUser?.indexOf('admin') !== -1) return AdminDashboardRoute
  if (loggedInUser?.indexOf('logistics') !== -1) return DefaultRoute
  if (loggedInUser?.indexOf('hr') !== -1) return HRDashboardRoute
  if (loggedInUser?.indexOf('any') !== -1) return AnyDashboardRoute
  if (loggedInUser?.indexOf('limited') !== -1) return LimitedDashboardRoute
  //if (userRole === 'Employee') return DefaultRoute
  //if (userRole === 'Customer') return '/access-control'
  userRole = ''
  console.log(userRole)
  return '/login'
}


export const selectThemeColors = theme => ({ // 👈️ React Select Theme Colors
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const numberWithCommas = x => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }

export const AmountFormatter = ({ amount }) => { return isNaN(Number(amount)) ? 'isNaN' : `$ ${numberWithCommas(Number(amount).toFixed(2))}` }

export const RequiredSymbol = () => <span className='text-danger'>*</span>

export const MTtoKg = (mt) => { // 👈️ Convert Metric Ton to Kilogram
  const newWeight = numberWithCommas((mt * 1000).toFixed(2))
  return `${newWeight} Kg`
}

export function isValidISOContainerNumber(containerNumber) {
  return CheckContainerNumberPerIsoSD(containerNumber)
  // return isValidContainerNumber(containerNumber)// || CheckContainerNumberPerIsoSD(containerNumber)
}

export const removeEmptyFields = (obj) => {
  const newObj = {}
  for (const key in obj) {
    if (obj[key] !== "") {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const GetSortedStatuses = (statuses) => {
  // Assuming 'statuses' is your array
  const sortedStatuses = [...statuses] // Create a copy of the array if necessary to avoid mutating the original array

  sortedStatuses.sort((a, b) => {
    // Convert the dates from string to Date objects for comparison
    const dateA = new Date(a.createdAt)
    const dateB = new Date(b.createdAt)

    return dateB - dateA // For descending order
  })

  // Now 'sortedStatuses' contains the statuses ordered by 'createdAt' in descending order
  return sortedStatuses
}
export function jsonToFormData(jsonObject, formData = new FormData(), parentKey = null) {
  Object.keys(jsonObject).forEach(key => {
    const fullPathKey = parentKey ? `${parentKey}[${key}]` : key
    if (jsonObject[key] instanceof File) {
      // Handle File object directly
      formData.append(fullPathKey, jsonObject[key])
    } else if (jsonObject[key] instanceof Object && !(jsonObject[key] instanceof Array)) {
      // Recurse into nested objects
      jsonToFormData(jsonObject[key], formData, fullPathKey)
    } else if (jsonObject[key] instanceof Array) {
      // Iterate through arrays
      jsonObject[key].forEach((item, index) => {
        if (typeof item === 'object' && !(item instanceof File)) {
          // Recurse into nested objects within arrays
          jsonToFormData(item, formData, `${fullPathKey}[${index}]`)
        } else {
          // Handle primitive types and File objects in arrays
          formData.append(`${fullPathKey}[]`, item)
        }
      })
    } else {
      // Handle primitive types
      formData.append(fullPathKey, jsonObject[key])
    }
  })
  return formData
}

// Function to calculate cargo weight
export const calculateCargoWeight = (item) => {
  // Checks if cargoType is either: Tote, Bag, or Pallet
  if (item.cargoType === 'TT' || item.cargoType === 'BAG' || item.cargoType === 'PLT') {
    // Multiplies cargoAmount by packageWeight and converts to KG (depending on unit)
    return item.cargoAmount * item.packageWeight * ((item.weightUnit === 'LBS' || item.weightUnit === 'lb') ? 0.453592 : (item.weightUnit === 'MT' ? 1000 : 1))
  } else {
    return item.packageWeight
  }
}

export const imgToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split('base64,')[1])
    reader.onerror = error => reject(error)
  })
}

export const stringToCommaseparated = (str) => {
  if (str === null || str === undefined || str === '' || str?.length === 0) return []
  return str?.split(',')?.map(el => el.trim())?.filter(item => item !== '') || []
}

export const excelTemplate = async (store, headers, headerValues, name) => {
  async function dataFormater(store) {
    const formattedData = []

    const filterDataHeaders = store.filter ? Object?.entries(store?.filter).reduce((result, [key, value]) => {
      if (value && value !== null) { // Check if the value is not an empty string
        let formattedKey = key
          .replace(/([A-Z])/g, " $1") // Add space before capital letters
          .toLowerCase() // Convert to lowercase
          .trim() // Remove leading and trailing spaces

        // Capitalize the first letter of each word
        formattedKey = formattedKey.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

        result.push(formattedKey)
      }
      return result
    }, []) : []

    const filterDataValues = store.filter ? Object.entries(store.filter).reduce((result, [, value]) => {
      if (value) { // Check if the value is truthy
        if (typeof value === 'string') {
          let formattedValue = value.toLowerCase()
          formattedValue = formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1)
          formattedValue = formattedValue.replace(/([A-Z])/g, " $1").trim()

          result.push(formattedValue)
        } else {
          result.push(value)
        }
      }
      return result
    }, []) : []

    // Order in excel
    formattedData.push([])
    formattedData.push([])
    formattedData.push(filterDataHeaders)
    formattedData.push(filterDataValues)
    formattedData.push([])
    formattedData.push(headers)

    for (let i = 0; i < store?.data?.length; i++) {
      const obj = store.data[i]
      const values = headerValues.map(headerValue => {
        let value
        if (typeof headerValue === 'string') {
          if (headerValue.includes('.')) {
            value = headerValue.split('.').reduce((o, k) => (o || {})[k], obj)
          } else {
            value = obj[headerValue]
          }
        } else {
          value = headerValue(obj)
        }

        // Check if value is a date string
        if (typeof value === 'string') {
          const iso8601Format = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/
          if (iso8601Format.test(value)) {
            const date = new Date(value)
            if (!isNaN(date.getTime())) { // value is a valid date string
              return formatDate(date)
            }
          }
        }

        return value
      })
      formattedData.push(values)
    }

    return formattedData
  }

  const finalData = await dataFormater(store)

  // Create name for file
  function customFormatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day}_${hours}:${minutes}:${seconds}`
  }

  const date = new Date()
  const formattedDate = customFormatDate(date)

  const fileName = `${name}-${formattedDate}`

  console.log(fileName)

  // Create workbook
  const workbook = new ExcelJS.Workbook()

  const response = await fetch('https://shipsmpl20st.blob.core.windows.net/store-receipts-test/image002.png')

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  // Creating blob / buffer
  const imageBlob = await response.blob()
  const arrayBuffer = await imageBlob.arrayBuffer()
  const imageBuffer = Buffer.from(arrayBuffer)

  const imageId = workbook.addImage({
    buffer: imageBuffer,
    extension: 'png'
  })

  // Create worksheet
  const worksheet = workbook.addWorksheet(fileName.includes('_') ? fileName.split('_')[0] : fileName)

  // Add data to worksheet
  finalData.forEach((row, rowIndex) => {
    const excelRow = worksheet.addRow(row)

    // Set height of first row to 50 (for image and headerName)
    if (rowIndex === 0) {
      excelRow.height = 50
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: {
          width: 3.55 * 96 / 2.54, // Custom Set Width
          height: 1.72 * 96 / 2.54 // Custom Set Height
        }
      })
      worksheet.getCell('B1').value = name
      worksheet.mergeCells('B1:C1')

      // Adding today's date
      const today = formatDate(new Date())
      worksheet.getCell('D1').value = today
    }

    // Applying styles to all cells
    excelRow.eachCell((cell, cellNumber) => {
      cell.alignment = { horizontal: 'center' }
      cell.font = cell.font || {}
      cell.font = { name: 'Aptos Narrow', size: 12 }

      const value = cell.value
      if (typeof value === 'string') {
        const datePattern = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT-\d{4} \(\w+ \w+ \w+\)$/
        if (datePattern.test(value)) {
          const date = new Date(value)
          if (!isNaN(date.getTime())) {
            cell.value = formatDate(date)
          }
        }
      }

      // Applying bold style to the Table Name
      if (rowIndex === 0 && cellNumber === 3) {
        const cell = worksheet.getCell('B1')
        cell.font = { name: 'Aptos Narrow', bold: true, size: 20 }
        cell.alignment = { horizontal: 'left' }
      }

      // Applying styles to header names
      if (rowIndex === 2 || rowIndex === 5) {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF000000' }
        }
      }

      // Applying border around all cells in rowIndex 2 or 3
      if (rowIndex === 2 || rowIndex === 3) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }

    })
  })

  // Set column width
  worksheet.columns = Array(25).fill({ width: 20 })

  // Write workbook to a buffer
  const buffer = await workbook.xlsx.writeBuffer()

  // Create a Blob from the buffer
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

  // Create a download link
  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.download = fileName

  // Append the download link to the body
  document.body.appendChild(downloadLink)

  // Simulate a click on the download link
  downloadLink.click()

  // Remove the download link from the body
  document.body.removeChild(downloadLink)
}

export const formatImageName = (listOfTags, pictureName) => {
  const pictureExtension = pictureName.split('.').pop()
  const pictureTags = listOfTags.join('_')
  return `${pictureTags}.${pictureExtension}`
}

export const customFormatDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}_${month}_${day}`
}

export const excelReportHeader = (worksheet, data, rowIndex) => {
    const setCellValueAndMerge = (cell, value, mergeRange, fontSize) => {
        worksheet.getCell(cell).value = value
        worksheet.getCell(cell).font = { bold: true, ...(fontSize && { size: fontSize }) }
        if (mergeRange) worksheet.mergeCells(mergeRange)
    }

    const applyPerimeterBorders = (startRow, endRow) => {
        for (let i = startRow; i <= endRow; i++) {
            const borders = {
                A: { left: { style: 'thin' }, ...(i === startRow && { top: { style: 'thin' } }), ...(i === endRow && { bottom: { style: 'thin' } }) },
                F: { right: { style: 'thin' }, ...(i === startRow && { top: { style: 'thin' } }), ...(i === endRow && { bottom: { style: 'thin' } }) }
            }
            worksheet.getCell(`A${i}`).border = borders.A
            worksheet.getCell(`F${i}`).border = borders.F
            for (let j = 2; j <= 5; j++) {
                worksheet.getCell(`${String.fromCharCode(64 + j)}${i}`).border = {
                    ...(i === startRow && { top: { style: 'thin' } }),
                    ...(i === endRow && { bottom: { style: 'thin' } })
                }
            }
        }
    }

    const rows = [
        { index: 2, cells: [['A3', 'Shipper:', null, 12], ['B3', data?.shipper, 'B3: D3', 18], ['E3', 'WTC Order #:', null, 12], ['F3', data?.transloadOrderNumber, null, 12]], height: 34 / 1.33 },
        { index: 3, cells: [['A4', 'Customer:', null, 12], ['B4', data?.customer, 'B4: D4', 14], ['E4', 'Customer Ref #:', null, 12], ['F4', data?.customerReference, null, 12]], height: 34 / 1.33 },
        { index: 4, cells: [['A5', 'Forwarder:', null, 12], ['B5', data?.oceanBooking?.forwarder, 'B5: D5', 14]], height: 34 / 1.33 },
        { index: 5, cells: [['A6', 'Booking:'], ['B6', data?.oceanBooking?.bookingNumber], ['C6', 'Shipper Ref:'], ['D6', data?.shipperReference], ['E6', 'Shipping Line:'], ['F6', data?.oceanBooking?.shippingLineCode]], height: 34 / 1.33 },
        { index: 6, cells: [['A7', 'Vessel/Voyage:'], ['B7', data?.oceanBooking?.vesselVoyage?.vesselName], ['C7', 'Delivered To:'], ['D7', data?.oceanBooking?.vesselVoyage?.terminalName], ['E7', 'L.R.D.:'], ['F7', data?.oceanBooking?.vesselVoyage?.latestReceivingDate && getLocalDateOnly(data?.oceanBooking?.vesselVoyage?.latestReceivingDate, 'T')]], height: 34 / 1.33 },
        { index: 7, cells: [['A8', 'Origin:'], ['B8', data?.oceanBooking?.origin], ['C8', 'Destination:'], ['D8', data?.oceanBooking?.destination], ['E8', 'E.T.D.:'], ['F8', data.oceanBooking?.vesselVoyage?.estimatedTimeOfDeparture && getLocalDateOnly(data.oceanBooking?.vesselVoyage?.estimatedTimeOfDeparture, 'T')]], height: 34 / 1.33 },
        { index: 8, cells: [['A9', `Total: ${data?.totalEquipmentCount || 0}`, null, 12], ['B9', `Empty: ${data?.totalEmptyEquipment || 0}`, null, 12], ['C9', `O/S: ${data?.totalOutStandingEquipment || 0}`, null, 12], ['D9', `Partially Loaded: ${data?.totalPartialEquipment || 0}`, null, 12], ['E9', `Loaded: ${data?.totalLoadedEquipment || 0}`, null, 12], ['F9', `Delivered: ${data?.totalDeliveredEquipment || 0}`, null, 12]], height: 34 / 1.33, fillColor: 'D9D9D9' }
    ]

    rows.forEach(row => {
        if (rowIndex === row.index) {
            worksheet.getRow(row.index + 1).height = row.height
            row.cells.forEach(([cell, value, mergeRange, fontSize]) => setCellValueAndMerge(cell, value, mergeRange, fontSize))
            if (row.fillColor) {
                for (let i = 1; i <= 6; i++) {
                    worksheet.getCell(`${String.fromCharCode(64 + i)}9`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: row.fillColor }
                    }
                }
            }
        }
    })

    if (rowIndex === 7) {
        applyPerimeterBorders(3, 5)
        applyPerimeterBorders(6, 8)
        applyPerimeterBorders(9, 9)
    }
}