// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
// 👇️ Endpoints
import {urlConfigCacheService, urlVesselVoyageService, urlCRMCompanyService,  urlLandBookingService } from '@src/endpoints'
// 👇️ Utils
import { getUriParams } from '@utils'

import toast from 'react-hot-toast'
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}
//function to remove all null, undefined, empty string from object else make the value caps
function clean(obj) {
  if (!obj) return {}
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName]
    } else if (typeof obj[propName] === 'string') {
      obj[propName] = obj[propName].toUpperCase()
    }
  }
  return obj
}

//GET
 //Land booking listing API
export const getLandBookings = createAsyncThunk('landBooking/getLandBookings', async ({ params, filterForm }, { rejectWithValue }) => {
  try {
    // Set default values for page and perPage if they are not present in filterForm
  const {  ...restFilterForm } = filterForm
    const query = getUriParams(params) // Converts the params object into a URL query string
    const response = await axios.post(`${urlLandBookingService}/GetAllLandBookingsList?${query}`, { ...clean(restFilterForm)})
      return {
      params,
      filterForm,
      data: response.data.data || [],
      totalPages: response.data.totalCount || 0,
      loaded: true
    }
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})
   
export const getVoyageID = async data => {
  const response = await axios.post(`${urlVesselVoyageService}/GetAllVoyage?`, { ...data })
  if (!response.data.success) return {
    success: false, message: 'No Voyage found'
  }
  return { success: true, data: response.data.data[0]?.id }
}

export const getCustomers = createAsyncThunk('landBooking/getCustomers', async () => {
  const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/CUSTOMER`)
  const railRoadResponse = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/RailRoad`)
  const drayageProviderResponse = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/DrayageProvider`)
  const regions = await axios.get(`${urlConfigCacheService}/GetAllSelect/RegionCity`)
  const consignee = await axios.get(`${urlConfigCacheService}/Consignee`)
  const containerSizes = await axios.get(`${urlConfigCacheService}?type=ContainerSize`)
  const ff = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/Freight%20Forwarder`)
    const bookingType = await axios.get(`${urlConfigCacheService}/BookingType`)
  const bookingStatus = await axios.get(`${urlConfigCacheService}/BookingStatus`)
  const vesselName = await axios.post(`${urlVesselVoyageService}/GetAllVessel`, { Id: '' }, // Empty data object if no request body is required
    {
      headers: {
        'Content-Type': 'application/json' // Set the Content-Type header
      }
    }
  )
  const containerSizesList = [{ value: '', label: 'Select...' }, ...(containerSizes.data.map(el => ({ value: el.id, label: el.id })))]
    const customers = [{ value: '', label: 'Select...' }, ...(response.data.map(el => ({ value: `${el.companyCode} - ${el.companyName}`, label: `${el.companyCode} - ${el.companyName}` })))]
    const railRoadList = [{ value: '', label: 'Select...' }, ...(railRoadResponse.data.map(el => ({ value: `${el.companyCode} - ${el.companyName}`, label: `${el.companyCode} - ${el.companyName}` })))]
    const drayageProviderList = [{ value: '', label: 'Select...' }, ...(drayageProviderResponse.data.map(el => ({ value: `${el.companyCode} - ${el.companyName}`, label: `${el.companyCode} - ${el.companyName}` })))]
    const combinedList = [...railRoadList, ...drayageProviderList]
  const regionsList = [{ value: '', label: 'Select...' }, ...(regions.data.data.map(el => ({ value: el.label, label: el.label })))]
  const consigneeList = [{ value: '', label: 'Select...' }, ...(consignee.data.map(el => ({ value: el.name, label: el.name })))]
  const ffList = [{ value: '', label: 'Select...', isForwarderOnly: '' }, ...(ff.data.map(el => ({ value: `${el.companyCode} - ${el.companyName}`, label: `${el.companyCode} - ${el.companyName}`, isForwarderOnly: el.isForwarderOnly})))]
  const bookingTypeList = [{ value: '', label: 'Select...' }, ...(bookingType.data.map(el => ({ value: el.name, label: el.name })))]
  const statusList = [{ value: '', label: 'Select...' }, ...(bookingStatus.data.map(el => ({ value: el.name, label: el.name })))]
  const vesselList = [{ value: '', label: 'Select...' }, ...(vesselName.data.data.map(el => ({ value: el.name, label: el.name })))]
  return { customers, regions: regionsList, consignee: consigneeList, ff: ffList, bookingType: bookingTypeList, bookingStatus: statusList, vesselName: vesselList, containerSizes: containerSizesList, carriers: combinedList }
})

// GET LAND BOOKING BY ID
export const getLandBookingById = createAsyncThunk(
  'landBooking/GetLandBookingId',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${urlLandBookingService}/GetLandBookingsId/${id}`)
      if (!response.data) {
        return rejectWithValue('No data received from GetLandBookingsId')
      }
      return {
        selectedLandBooking: response.data,
        loadedLandBooking: true
      }
    } catch (error) {
      console.error('Error in getLandBookingById:', error)
      return rejectWithValue(error.response?.data?.message || 'An error occurred while fetching land booking data')
    }
  }
)

// Add or Update Land Bookings
export const addLandBooking = createAsyncThunk(
  'landBooking/AddOrUpdateLandBookings',
  async (data, { dispatch }) => {
    const { landBookingData, params, filterForm, id } = data
    try {
      // Step 1: Add or update land booking
      const response = await axios.post(`${urlLandBookingService}/AddOrUpdateLandBookings`, { ...landBookingData, id })

      if (!response.data || !response.data.data || !response.data.data.id) {
        throw new Error('Invalid response from AddOrUpdateLandBookings')
      }

      const landId = response.data.data.id
      const createdEmployee = response.data.data.createdBy

      // Step 2: Get the updated land booking by ID
      const getByIdResponse = await dispatch(getLandBookingById(landId))

      if (getByIdResponse.type === 'landBooking/GetLandBookingId/rejected') {
        throw new Error(getByIdResponse.payload || 'Failed to fetch updated land booking data')
      }

      // Step 3: Refresh the data list
      await dispatch(getLandBookings({ params, filterForm }))

      return {
        landBookingData: getByIdResponse.payload.selectedLandBooking,
        landId,
        createdEmployee
      }
    } catch (error) {
      console.error('Error in addLandBooking:', error)
      throw error
    }
  }
)

// // /Delete land Bookings -delete
export const deleteBooking = createAsyncThunk('landBooking/deleteBooking', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${urlLandBookingService}/DeleteLandBookings/${id}`)
  response.data.success === true ? ToastMessage('Promotion', response.data.messages[0], true) : ToastMessage('Promotion', response.data.errors[0], false)
  const { params, filterForm } = getState().landBooking
  await dispatch(getLandBookings({ params, filterForm }))
  return id
})

export const landBookingSlice = createSlice({
    name: 'landBooking',
  initialState: {
    loaded: false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    terminal: {},
    total: 1,
    params: {},
    filterForm: {},
    allData: {},
    status: [],
    notes: [],
    allTimeSheetUser: [],
    allContacts: [],
    customers: [],
    consignee: [],
    freightForwarder: [],
    regions: [],
    bookingType: [],
    bookingStatus: [],
    vesselName: [],
    containerSizes: [],
    selectedLandBooking: null,
    loadedLandBooking: false,
    carriers: [],
     landId: '',
    createdEmployee: ''
  
  },
  name: 'landBooking',

 
  reducers: {},
  extraReducers: builder => {
    builder
     
     .addCase(getLandBookings.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(addLandBooking.fulfilled, (state, action) => {
        state.selectedLandBooking = action?.payload?.landBookingData
        state.landId = action?.payload?.landId
        state.createdEmployee = action?.payload?.createdEmployee
      })
      .addCase(getLandBookingById.fulfilled, (state, action) => {
      
        state.selectedLandBooking = action.payload.selectedLandBooking
        state.loadedLandBooking = action.payload.loadedLandBooking
      })
     
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.customers
        state.consignee = action.payload.consignee
        state.freightForwarder = action.payload.ff
        state.containerSizes = action.payload.containerSizes
        state.regions = action.payload.regions
        state.bookingType = action.payload.bookingType
        state.bookingStatus = action.payload.bookingStatus
        state.vesselName = action.payload.vesselName
        state.carriers = action.payload.carriers
      })
      //anything fulfilled will set loaded to true
      .addMatcher(action => action.type.endsWith('/fulfilled'), (state) => {
        state.loaded = true
      })
      //anything pending will set loaded to false
      .addMatcher(action => action.type.endsWith('/pending'), (state) => {
        state.loaded = false
      })
      //reset loaded to true if rejected
      .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
        state.loaded = true
      })

  }
})

export default landBookingSlice.reducer