//endpoints
import { urlIdentityService, urlCRMCompanyService, urlCRMCompanyAddressService, urlServiceAgreement, urlCRMContactService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import toast from 'react-hot-toast'
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading={heading} response={isSuccess} message={message} />
  ))
}

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${urlIdentityService}/GetUserDetailsById/${id}`)
  return response.data
})

// Company Actions

export const getCompanyById = createAsyncThunk('Company/GetCompanyById', async id => {
  const response = await axios.get(`${urlCRMCompanyService}/GetById/${id}`)
  return {
    selectedCompany: response.data,
    loadedCompany: true
  }
})

export const getAllCompany = createAsyncThunk('Company/getAllCompany', async params => {
  const uri = getUriParams(params)
  const {role, type} = params.filterForm
  const response = await axios.post(`${urlCRMCompanyService}/GetAllCompany?${uri}&&role=${role}&&type=${type}`)
  return {
    params,
    data: response.data,
    totalPages: response.data[0]?.totalCount || 0
  }
})

export const createCompany = createAsyncThunk('Company/CreateCompany', async (createCompanyViewModal, { dispatch, getState }) => {
  const response = await axios.post(`${urlCRMCompanyService}/Create`, createCompanyViewModal)
  await dispatch(getAllCompany(getState().crmCompany.params))
  return response
})

export const updateCompany = createAsyncThunk('Company/UpdateCompany', async (companyViewModal, { dispatch }) => {
  await axios.put(`${urlCRMCompanyService}/Update`, companyViewModal)
  await dispatch(getCompanyById(companyViewModal.id))
  // await dispatch(getAllCompany())
  return companyViewModal
})

export const deleteCompany = createAsyncThunk('Company/deleteCompany', async (id, { dispatch, getState }) => {
  await axios.delete(`${urlCRMCompanyService}/Delete/${id}`)
  await dispatch(getAllCompany(getState().crmCompany.params))
  //await dispatch(getCompanyById(companyId))
  await dispatch(getAllCompany())
  return id
})

//Company Address Actions
export const getAllCompanyAddress = createAsyncThunk('Company/getAllCompanyAddress', async ({ companyId, ...params }) => {
  const uriParams = {
    ...params,
    companyId // Include the company ID in the parameters
  }

  const uri = getUriParams(uriParams)
  const response = await axios.post(`${urlCRMCompanyAddressService}/GetAllCompanyAddress?${uri}`)
  return {
    params: uriParams,
    data: response.data,
    totalPages: response.data[0]?.totalCount || 0
  }
})

export const createAddress = createAsyncThunk('Company/CreateAddress', async (createAddressViewModal, { dispatch }) => {
  const response = await axios.post(`${urlCRMCompanyAddressService}/Create`, createAddressViewModal)
  // await dispatch(getAllCompanyAddress({ companyId: createAddressViewModal.companyId, page: 1, perPage: 7, ...params }))
  dispatch(getCompanyById(createAddressViewModal.companyId))
  return response
})

export const updateAddress = createAsyncThunk('Company/UpdateAddress', async (addressViewModal, { dispatch }) => {
  await axios.put(`${urlCRMCompanyAddressService}/Update`, addressViewModal)
  dispatch(getCompanyById(addressViewModal.companyId))
  return addressViewModal
})


export const deleteAddress = createAsyncThunk('Company/deleteAddress', async (id, { dispatch, getState }) => {
  await axios.delete(`${urlCRMCompanyAddressService}/Delete/${id}`)
  const companyId = getState().crmCompany.selectedCompany?.id // Get companyId from the state
  await dispatch(getCompanyById(companyId)) // Update company details
  await dispatch(getAllCompany()) // Update company address list
  return companyId
})

//company Contact Actions
export const getAllCompanyContact = createAsyncThunk('Company/getAllCompanyContact', async ({ companyId, ...params }) => {
  const uriParams = {
    ...params,
    companyId // Include the company ID in the parameters
  }

  const uri = getUriParams(uriParams)
  const response = await axios.post(`${urlCRMContactService}/GetAllCompanyContact?${uri}`)


  return {
    params: uriParams,
    data: response.data,
    totalPages: response.data[0]?.totalCount || 0
  }
})

export const createContact = createAsyncThunk('Company/CreateContact', async (createContactViewModal, { dispatch }) => {
  const response = await axios.post(`${urlCRMContactService}/AddContact`, createContactViewModal)
  dispatch(getCompanyById(createContactViewModal.companyId))
  return response
})

export const updateContact = createAsyncThunk('Company/UpdateContact', async (contactViewModal, { dispatch }) => {
  await axios.put(`${urlCRMContactService}/UpdateContact`, contactViewModal)
  dispatch(getCompanyById(contactViewModal.companyId))
  return contactViewModal
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const GetAllServiceAgreements = createAsyncThunk('Company/GetAllServiceAgreements', async (data) => {
  const response = await axios.post(`${urlServiceAgreement}/GetAllServiceAgreements`, data)
  return response.data
})

export const DeleteServiceAgreement = createAsyncThunk('Company/DeleteServiceAgreement', async (id, { dispatch, getState }) => {
  await axios.delete(`${urlServiceAgreement}/DeleteServiceAgreement/${id}`)
  await dispatch(GetAllServiceAgreements(getState().crmCompany.selectedCompany.id))
  return id
})

export const AddServiceAgreement = async (data) => {
  const response = await axios.post(`${urlServiceAgreement}/AddServiceAgreement`, data)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}

export const UpdateServiceAgreement = async (data) => {
  const response = await axios.post(`${urlServiceAgreement}/UpdateServiceAgreement`, data)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}


export const crmCompanySlice = createSlice({
  name: 'crmCompany',
  name: 'crmCompanyAddress',

  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    serviceAgreements: [],
    selectedUser: null,
    selectedCompany: null,
    selectedCompanyAddress: null,
    selectedCompanyContact: null
  },
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

      .addCase(updateAddress.fulfilled, (state, action) => {
        state.selectedCompanyAddress = action.payload
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.selectedCompanyContact = action.payload
      })

      .addCase(getAllCompany.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages

      })
      .addCase(getAllCompanyAddress.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.selectedCompany = action.payload.selectedCompany[0]
        state.loadedCompany = action.payload.loadedCompany
      })
      // Set loading as true when initiating a getCompanyById call
      .addCase(getCompanyById.pending, (state) => {
        state.loadedCompany = false
      })
      .addCase(GetAllServiceAgreements.fulfilled, (state, action) => {
        state.serviceAgreements = action.payload
      })
  }
})

export default crmCompanySlice.reducer